import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react'

import SpeedTest from '../../../components/data-centers/speed-test'
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo';
import StructuredData from '../../../components/structured-data'

type Props = {
  location: Record<string, unknown>
  data: {
    hero: { childImageSharp: { fluid: FluidObject } }
    region: { childImageSharp: { fluid: FluidObject } }
    amsCenter: { childImageSharp: { fluid: FluidObject } }
    wpPage: {
      uri: string
      seo: Record<string, unknown>
    }
  }
};

const url = 'https://amsterdam-test.liquidweb.name/files/100mb.bin'

const PageEUCentral: React.FC<Props> = ({ data }) => {
  const { hero, region, amsCenter, wpPage } = data

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="py-10 px-5 lg:py-20 relative text-white">
        <div className="relative z-30 container">
          <h1 className="text-white mb-4">EU-Central Region</h1>
          <h2 role="doc-subtitle" className="font-semibold">
            Our European Data Center Hub
          </h2>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `rgba(46,60,75,0.93)`,
          }}
          role="presentation"
        ></div>
        <GatsbyImage
          image={hero.childImageSharp.gatsbyImageData}
          alt="EU-Central Region"
          className="w-full h-full left-0 top-0 z-10 data-center-bg"
          style={{ position: `absolute` }} />
      </div>
      <div className="bg-lw-lt-gray">
        <div className="px-8 container py-8 lg:py-10 grid grid-cols-5 gap-10 items-center">
          <div className="col-span-5 lg:col-span-3">
            <h2 className="mb-6">Overview</h2>
            <div className="mb-2">
              Located in Amsterdam, the Netherlands, our EU-Central data center
              provides minimal latency to European locations. There&apos;s never a
              question of network speed. Your servers are directly connected to
              two of Europe&apos;s largest Internet exchanges (AMS-IX and NL-IX).
            </div>
            <div>
              Our EU-Central region provides speedy access to European and Asian
              users while still guaranteeing our 24/7/365 support. Our
              geographically diverse locations allow you to enhance your hosting
              by adding servers closer to your users.
            </div>
          </div>
          <div className="col-span-5 lg:col-span-2">
            <GatsbyImage image={region.childImageSharp.gatsbyImageData} alt="EU-Central Region" />
          </div>
        </div>
      </div>
      <div className="px-8 py-8 lg:py-16 container">
        <h2 className="mb-6">EU-Central Region</h2>
        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="col-span-2 lg:col-span-1">
            <div>
              <span className="font-bold">Location:</span> Amsterdam, The
              Netherlands
            </div>
            <div>
              <span className="font-bold">Data Centers:</span> 1
            </div>
            <div>
              <span className="font-bold">Providers:</span> Level 3, Cogent
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div>
              <span className="font-bold">Established:</span> 2014
            </div>
            <div>
              <span className="font-bold">Zones:</span> 2
            </div>
          </div>
        </div>
        <h4 className="pb-6 text-xl lg:text-2xl">Amsterdam Data Center</h4>
        <div className="grid grid-cols-4 gap-10">
          <div className="col-span-4 lg:col-span-1">
            <GatsbyImage
              image={amsCenter.childImageSharp.gatsbyImageData}
              alt="Amsterdam Data Center" />
          </div>
          <div className="col-span-4 lg:col-span-1">
            <div className="font-bold">Amsterdam Data Center</div>
            <div>Capacity: 8,000-25,000 Servers</div>
            <div>PCI-DSS Compliant</div>
            <div>ISAE 3402 Compliant</div>
            <div> ISO9001 Certified</div>
            <div>ISO14001 Certified</div>
            <div>ISO27001 Certified</div>
            <div>AMS-IX Compliant</div>
          </div>
        </div>
      </div>
      <div className="bg-lw-purple">
        <div className="px-8 py-8 container text-center text-white">
          <div className="text-lg uppercase font-semibold mb-4">
            Curious how our network performs?
          </div>
          <div className="text-2xl font-semibold mb-8">
            Test The Speed of Our EU-Central Region Now!
          </div>
          <SpeedTest url={url} />
          <div className="text-xs">
            Note: Speeds are limited by your local device&apos;s connection. Users on
            a mobile device using 3G will see significantly slower speeds than
            those hardwired to a DSL/Cable Modem.
          </div>
        </div>
      </div>
      <div className="px-8 py-8 lg:py-20 grid grid-cols-2 container gap-10">
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Power System</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>12.5 MW current capacity</li>
            <li>60 MW maximum capacity</li>
            <li>20x 1.25 MW diesel generators</li>
            <li>2N power redundancy</li>
            <li>200+ watts per sq. ft power densities</li>
            <li>PUE 1.2 efficiency rating</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Network Gear</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>Diverse paths into building</li>
            <li>Dedicated meet-me room</li>
            <li>Bandwidth co-op solutions</li>
            <li>Carrier neutral</li>
            <li>On-net transport to most major global cities</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Cooling</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>N+1 redundancy</li>
            <li>
              Hal 1,2,3, and 4: Closed loop chilled water with CRAC units and
              free air coolers
            </li>
            <li>Hal 5 and 6: Indirect adiabatic system</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Security</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>24/7/365 on-site and mobile guards</li>
            <li>
              3 meter perimeter fencing, secured loading dock, and window-less
              building
            </li>
            <li>
              Multi-level access authorization with biometric scan, electronic
              ID, and 24/7/365 monitored alarms
            </li>
            <li>
              State of the art closed circuit TV security cameras (inside &amp;
              outside)
            </li>
          </ul>
        </div>
      </div>
      <div className="p-8 container">
        <Link to="/data-centers" aria-label="Back to Data Centers">
          &#x3c; Back to Data Centers
        </Link>
      </div>
    </Layout>
  );
}

export default PageEUCentral

export const query = graphql`query EUCentralPage {
  wpPage(slug: {eq: "eu-central"}) {
    uri
    chatDepartment
    seo {
      ...postSeoFields
    }
  }
  hero: file(relativePath: {eq: "about-us/data-centers/traffic-spikes.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
  region: file(
    relativePath: {eq: "about-us/data-centers/data-center-eu-central.png"}
  ) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 500
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  amsCenter: file(relativePath: {eq: "about-us/data-centers/ams-datacenter.png"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 400
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`
